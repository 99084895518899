var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.slides.length)?_c('v-container',{staticClass:"px-0 py-0",attrs:{"fluid":""}},[_c('RatioBox',{staticClass:"grey",staticStyle:{"position":"relative"},attrs:{"width":"100%","aspectRatio":1264 / 609}},[_c('div',{staticClass:"d-flex justify-space-between arrows-position w100"},[_c('v-btn',{staticClass:"arrow-background",class:{
					'mx-13 arrow-size-td': _vm.$b.td,
					'mx-8 arrow-size-mobile': _vm.$b.m,
					'v-hidden': !_vm.canMovePrev,
				},attrs:{"icon":""},on:{"click":_vm.movePrev}},[_c('v-icon',{staticClass:"arrow-reverse arrow-icon",attrs:{"size":"30px"}},[_vm._v("mdi-play")])],1),_c('v-btn',{staticClass:"arrow-background",class:{
					'mx-13 arrow-size-td': _vm.$b.td,
					'mx-8 arrow-size-mobile': _vm.$b.m,
					'v-hidden': !_vm.canMoveNext,
				},attrs:{"icon":""},on:{"click":_vm.moveNext}},[_c('v-icon',{staticClass:"arrow-icon",attrs:{"size":"30px"}},[_vm._v("mdi-play")])],1)],1),_vm._l((_vm.data.slides),function(i,index){return _c('div',{key:index,staticClass:"overflow-hidden slide w100",class:{ transitioning: i.transitioning },style:(i.style)},[_c('Media',{attrs:{"src":i.img,"img-class":{ 'zoom-in': i.transitioning },"cover":"","width":"100%"}},[_c('div',{staticClass:"h100 w100 d-flex justify-center flex-column align-center background-overlay",class:{ 'd-none': !i.transitioning }},[_c('h3',{staticClass:"font-2 font-sm-5 font-md-7 font-weight-bold white--text",class:{ 'title-active': i.transitioning }},[_vm._v(" "+_vm._s(i.title)+" ")]),(i.subtitle)?_c('h4',{staticClass:"font-1 font-sm-3 font-md-5 font-weight-medium white--text",class:{ 'subtitle-active': i.transitioning }},[_vm._v(" "+_vm._s(i.subtitle)+" ")]):_vm._e(),_c('v-btn',{staticClass:"cta font-weight-bold mt-5",class:{
							'btn-active': i.transitioning && i.subtitle,
							'subtitle-active': i.transitioning && !i.subtitle,
						},attrs:{"to":i.link,"small":_vm.$b.m}},[_vm._v(_vm._s(i.cta))])],1)])],1)})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }