<script>
import sortBy from 'lodash/sortBy'

export default {
	props: {
		data: Object,
		index: Number,
	},
	data() {
		return {
			totalCompleted: 0,
			shop: null,
			error: false,
			filters: null,
			loading: false,
		}
	},
	methods: {
		async loadShop(totalCompleted) {
			await this.$shopApi.post({
				url: '/catalog/shop',
				data: this.getPostData(totalCompleted),
				loading: (v) => (this.loading = v ? totalCompleted - 1 : false),
				onSuccess: ({ data }) => {
					if (data.shop === false) {
						this.error = true
					} else {
						this.shop = data.shop
					}
				},
			})

			this.totalCompleted = totalCompleted
			this.resetFilters()

			this.$nextTick(() => {
				setTimeout(() => {
					this.$refs.input[this.totalCompleted - 1]?.blur()
					this.$refs.input[this.totalCompleted]?.focus()
				}, 100)
			})
		},
		onFilterChanged(idx, value) {
			let totalCompleted = idx + (value ? 1 : 0)
			if (totalCompleted > 0) {
				for (let x = 0; x < this.filters.length; x++) {
					if (x >= totalCompleted) {
						this.filters[x].value = null
						this.filters[x].disabled = true
					}
				}
				this.loadShop(totalCompleted)
			}
		},
		getPostData(totalCompleted) {
			let post = {}
			// { filters: ['shop'], attrs: { ano: '2010', 'marca-vehiculo': 'ford' }, page: 1 }
			for (let x = 0; x < totalCompleted; x++) {
				let filter = this.filters[x]
				if (filter.type == 'category') {
					let category = filter.categories.find((c) => c.id == filter.value)
					post.filters = (post.filters || []).concat(category.urlName)
				} else {
					let attrVal = filter.attrKey.values.find((v) => v.id == filter.value)
					post.attrs = post.attrs || {}
					post.attrs[filter.attrKey.urlK] = attrVal.urlV
				}
			}

			if (!post.filters?.length) {
				post.filters = [process.env.VUE_APP_SHOP_ALL_KEYWORD]
			}

			post.page = 1
			post.limit = 8

			return post
		},
		resetFilters() {
			let filters = this.filters || [...this.data.filters]
			filters.forEach((filter, i) => {
				filter.label = this.getFilterLabel(filter)
				filter.items = this.getFilterItems(filter, i)
				filter.disabled = !filter.items.length
				filter.value = filter.value || null
			})
			this.filters = filters.filter((filter) => !!filter.label)
		},
		getFilterLabel(filter) {
			switch (filter.type) {
				case 'attrKey':
					return filter.attrKey.k
				case 'category':
					return 'Categoría'
				default:
					return null
			}
		},
		getFilterItems(filter, i) {
			if (i > this.totalCompleted) return []
			if (i < this.totalCompleted) return filter.items
			let items = null
			let possibleFilters = this.shop?.possibleFilters
			switch (filter.type) {
				case 'attrKey':
					items = filter.attrKey.values.map((val) => ({ text: val.v, value: val.id, urlV: val.urlV }))
					if (possibleFilters) {
						let group = possibleFilters.attrsGroups?.find((_group) => {
							let urlK = _group.items[0].value[0]
							return filter.attrKey.urlK == urlK
						})
						if (group) {
							items = items.filter((item) => {
								return group.items.find((groupItem) => {
									return groupItem.value[1] == item.urlV
								})
							})
						} else {
							return []
						}
					}
					break
				case 'category':
					items = filter.categories.map((cat) => ({
						text: cat.name,
						value: cat.id,
						urlName: cat.urlName,
					}))
					if (possibleFilters) {
						if (possibleFilters.category?.length) {
							items = items.filter(
								(item) => !!possibleFilters.category.find((_item) => _item.value == item.urlName)
							)
						} else {
							return []
						}
					}
					break
				default:
					return []
			}
			return sortBy(items, 'text')
		},
	},
	created() {
		this.resetFilters()
	},
}
</script>

<template>
	<Container>
		<v-row
			class="justify-center mx-1 my-1 align-center"
			:class="[
				($bem('__header'),
				{
					'mx-4': $b.m,
					'mb-4': !data.subtitle,
					'modern-row': data.titleStyle == 'modern',
				}),
			]"
		>
			<div class="col d-flex double-col pa-0 mr-2" v-if="data.titleStyle == 'double'">
				<div class="double"></div>
				<div class="double"></div>
			</div>
			<h2
				class="header d-block"
				:class="{
					modern: data.titleStyle == 'modern',
					short: data.titleStyle == 'short',
					'font-4': $b.td,
					'font-3': $b.m,
				}"
			>
				{{ data.title }}
			</h2>
			<div class="col d-flex double-col pa-0 ml-2" v-if="data.titleStyle == 'double'">
				<div class="double"></div>
				<div class="double"></div>
			</div>
		</v-row>
		<v-row
			class="justify-center mx-1 my-3"
			:class="
				($bem('__header'),
				{
					'mx-4': $b.m,
				})
			"
			v-if="data.subtitle"
		>
			<p class="mb-0 font-2 base--text">{{ data.subtitle }}</p>
		</v-row>

		<div class="d-flex flex-wrap justify-center align-center py-5">
			<Autocomplete
				v-for="(filter, i) of filters"
				ref="input"
				@change="onFilterChanged(i, $event)"
				v-model="filter.value"
				:key="i"
				:label="filter.label"
				:items="filter.items"
				:disabled="filter.disabled"
				class="mx-3"
				:loading="loading === i"
				:color="$vars.cta"
			>
				<template #append>
					<v-progress-circular
						:color="$vars.cta"
						size="20"
						indeterminate
						v-if="loading === i"
						width="2"
					/>
					<v-icon
						:color="$vars.cta"
						v-else-if="totalCompleted > i && (loading === false || loading > i)"
					>
						mdi-check-circle
					</v-icon>
					<v-icon v-else> $dropdown </v-icon>
				</template>
			</Autocomplete>
		</div>
		<div v-if="shop" class="pt-5" :class="{ disabled: loading !== false }">
			<ProductsLayout
				class="justify-center"
				v-if="shop.products.length"
				:cards-per-line="4"
				:products="shop.products"
			/>
		</div>
	</Container>
</template>

<style scoped>
.disabled {
	opacity: 0.3;
}
.disabled,
.disabled ::v-deep * {
	pointer-events: none;
}

.modern-row {
	border-bottom: 1px solid var(--primary);
}

@media (max-width: 599.98px) {
	.modern-row {
		flex-wrap: nowrap;
	}
}

h2.modern::after {
	content: '';
	border-bottom: 4px solid var(--headertext);
	position: relative;
	width: 102%;
	display: block;
	bottom: -1px;
}

h2.short::after {
	content: '';
	border-bottom: 3.4px solid var(--headertext);
	position: relative;
	width: 20%;
	display: block;
	bottom: -1px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 6px;
}

.double-col {
	flex-direction: column;
}

.double {
	height: 1.4px;
	background: var(--headertext);
	margin: 1px 0;
}
</style>
