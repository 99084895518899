var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.sliderCards && _vm.data.sliderCards.length)?_c('Container',{staticClass:"no-mobile-pa",class:{ 'my-n6 my-sm-0': _vm.index == 0 }},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('Carousel',_vm._b({staticClass:"w100",attrs:{"dotContainerFullWidthMobile":_vm.data.sliderCards.length >= 15,"loop":"","autoplay-delay":Number(_vm.data.autoplayDelay),"center":"","arrowsBgHalfRounded":""},on:{"slideIndex":function($event){return _vm.getIndex($event)}}},'Carousel',
					_vm.$b.props({
						showArrows: true,
						showDots: true,
					})
				,false),_vm._l((_vm.data.sliderCards),function(item,i){return _c('div',{key:i,staticClass:"overflow-hidden",class:_vm.$b({ td: 'rounded-lg' })},[_c('SafeLink',{class:_vm.$b({ td: 'rounded-lg' }),attrs:{"to":item.link}},[_c('Media',{attrs:{"width":"100%","src":_vm.$b({ mt: item.imgMobile, d: item.img }),"img-class":[
								_vm.$b({ td: 'rounded-lg' }),
								{ 'zoom-in': i == _vm.slideIndex && item.zoomEffect } ]}},[_c('div',{staticClass:"h100 w100 d-flex justify-center flex-column align-center",class:{
									'background-overlay': item.darkenSlide,
									'rounded-lg': _vm.$b.td,
								}},[(item.title)?_c('h2',{staticClass:"font-4 font-lg-16 font-md-7 font-weight-bold primary pa-1 text-center",class:_vm.textClass(i == _vm.slideIndex, item.textEffect, 'title')},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.subtitle)?_c('h3',{staticClass:"font-1 font-lg-8 font-md-5 font-weight-medium secondary pa-1 text-center",class:[
										_vm.textClass(i == _vm.slideIndex, item.textEffect, 'subtitle'),
										{
											'mt-2': item.title && !_vm.$b.m,
											'mt-1': item.title && _vm.$b.m,
										} ]},[_vm._v(" "+_vm._s(item.subtitle)+" ")]):_vm._e(),(item.cta)?_c('v-btn',{staticClass:"cta font-weight-bold",class:[
										_vm.btnClass(i == _vm.slideIndex, item.subtitle, item.textEffect),
										{
											'mt-5': !_vm.$b.m,
											'mt-1': _vm.$b.m,
										} ],attrs:{"xSmall":_vm.$b.m}},[_vm._v(_vm._s(item.cta))]):_vm._e()],1)])],1)],1)}),0)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-row',_vm._l((_vm.sideItems),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"6","sm":"12"}},[_c('SafeLink',{staticClass:"w100",attrs:{"to":item.link}},[_c('Media',{attrs:{"src":item.img,"img-class":"rounded-lg","width":"100%"}},[_c('h1',{staticClass:"seo-text"},[_vm._v(_vm._s(item.textSEO))])])],1)],1)}),1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }