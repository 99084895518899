var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.products && _vm.data.products.length)?_c('Container',{staticClass:"align-center no-mobile-px",class:(_vm.$b({ m: 'pa-0' }), _vm.$bem('products-board'))},[_c('Title',{attrs:{"title":_vm.data.title,"btn":{ link: _vm.data.link, linkText: _vm.data.linkText },"titleStyle":_vm.data.titleStyle}}),_c('v-row',{staticClass:"align-center",class:_vm.data.inverted && 'flex-row-reverse'},[(_vm.data.imgEnabled)?_c('v-col',{staticClass:"py-0",class:{
				'col-3': _vm.$b.d && _vm.productsLimit.length <= 4,
				'col-4': _vm.$b.d && _vm.productsLimit.length > 4,
				'col-12': _vm.$b.mt,
			}},[_c('Media',{attrs:{"src":_vm.$b({ d: _vm.data.img, mt: _vm.data.imgMobile }),"cover":"","width":"100%","img-class":_vm.$b({ d: 'rounded-lg' })}},[(_vm.data.textImgEnabled)?_c('div',{staticClass:"h100 w100 d-flex flex-column align-center",class:[
						_vm.$b({ d: 'rounded-lg', d: _vm.textPosition, mt: 'justify-center' }),
						_vm.data.textImgOverlay && 'background-overlay' ]},[_c('h3',{staticClass:"font-2 font-sm-5 font-md-7 font-weight-bold strong"},[_vm._v(" "+_vm._s(_vm.data.titleImg)+" ")]),(_vm.data.ctaImg)?_c('Button',{staticClass:"cta--text px-2",class:{ 'outlined-mobile my-3': _vm.$b.m, 'outlined my-6': _vm.$b.td },attrs:{"to":_vm.data.linkImg,"text":""}},[_vm._v(" "+_vm._s(_vm.data.ctaImg)+" ")]):_vm._e()],1):_vm._e()])],1):_vm._e(),_c('v-col',{class:_vm.data.imgEnabled && { 'py-0 mt-n2': _vm.$b.mt },style:(_vm.data.imgEnabled && _vm.$b({ mt: 'z-index: 1' }))},[_c('v-row',{class:_vm.data.imgEnabled && _vm.productsLimit.length < 4 && _vm.$b({ t: 'justify-center' })},_vm._l((_vm.productsLimit),function(i,index){return _c('v-col',{key:index,staticClass:"d-flex justify-center",class:[{ 'px-0': _vm.$b.m, 'px-1': _vm.$b.td }, _vm.colSize]},[_c('ProductCard',{class:{ imgSmall: _vm.colSize == 'col-2' },attrs:{"small":"","product":i,"listId":_vm.listId,"listIndex":index,"verticalCard":!_vm.data.imgEnabled && _vm.productsLimit.length < 7 && _vm.$b.td
								? false
								: !_vm.data.imgEnabled && _vm.productsLimit.length == 1 && _vm.$b.m
								? false
								: _vm.data.imgEnabled && _vm.productsLimit.length <= 6 && _vm.$b.td
								? false
								: true}})],1)}),1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }