<script>
import LazyHydrate from 'vue-lazy-hydration'
export default {
	metaInfo() {
		let metaInfo = {}
		if (this.$route.meta.pageTitle) {
			metaInfo.title = this.$route.meta.pageTitle
		}
		if (this.$route.meta.description) {
			metaInfo.meta = [{ vmid: 'd', name: 'description', content: this.$route.meta.metaDescription }]
		}
		return metaInfo
	},
	components: {
		LazyHydrate,
	},
	data() {
		return {
			items: [],
			loading: false,
		}
	},
	watch: {
		'$route.meta.dynamicPageKey'() {
			this.$fetch()
		},
	},
	async fetch() {
		await this.$shopApi.get('/dynamic-page-data', {
			query: { dynamicPageKey: this.$route.meta.dynamicPageKey },
			loading: (v) => (this.loading = v),
			onSuccess: ({ data }) => {
				this.items = data.items
			},
		})
	},
	fetchKey() {
		return `dynamic_page_data_${this.$route.meta.dynamicPageKey}`
	},
	trackPageView: true,
}
</script>

<template>
	<v-progress-linear indeterminate light color="primary" v-if="loading" />
	<LazyHydrate v-else when-idle>
		<DynamicPageItems :items="items" :css-vars-key="$route.meta.dynamicPageKey" />
	</LazyHydrate>
</template>
