<script>
export default {
	props: {
		data: Object,
	},
	computed: {
		cards() {
			if (this.data.boardType == 2) {
				return this.data.cardsTwoCol
			} else {
				return this.data.cardsFourCol
			}
		},
	},
}
</script>

<template>
	<Container>
		<v-row>
			<v-col :cols="$b({ td: data.boardType == 2 ? 6 : 3, m: 12 })" v-for="(item, i) of cards" :key="i">
				<OfferCard :card="item" :type="data.boardType" />
			</v-col>
		</v-row>
	</Container>
</template>
