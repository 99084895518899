<script>
export default {
	props: {
		data: Object,
	},
	data() {
		return {
			currentIndex: this.indexValue || 0,
			canMove: true,
		}
	},
	computed: {
		canMovePrev() {
			if (this.currentIndex > 0 && this.canMove) return true
			else return false
		},
		canMoveNext() {
			if (this.currentIndex < this.data.slides.length - 1 && this.canMove) return true
			else return false
		},
	},
	created() {
		this.init()
	},
	mounted() {
		this.in(this.currentIndex)
	},
	methods: {
		move(by) {
			if (!this.canMove) return
			this.canMove = false
			this.out(this.currentIndex)
			this.currentIndex += by
			this.in(this.currentIndex)
			setTimeout(() => (this.canMove = true), 2000)
		},
		movePrev() {
			this.move(-1)
		},
		moveNext() {
			this.move(1)
		},
		init() {
			let slide = this.data.slides[0]
			this.$set(slide, 'transitioning', true)
			this.$set(slide, 'style', { opacity: 1, zIndex: 2 })
		},
		in(idx) {
			let slide = this.data.slides[idx]
			this.$set(slide, 'transitioning', true)
			this.$set(slide, 'style', { opacity: 1, zIndex: 2 })
		},
		out(idx) {
			let slide = this.data.slides[idx]
			slide.style = { opacity: 1, zIndex: 1 }
			setTimeout(() => {
				slide.style = {}
				slide.transitioning = false
			}, 1500)
		},
	},
}
</script>

<template>
	<v-container fluid v-if="data.slides.length" class="px-0 py-0">
		<RatioBox width="100%" :aspectRatio="1264 / 609" style="position: relative" class="grey">
			<div class="d-flex justify-space-between arrows-position w100">
				<v-btn
					icon
					class="arrow-background"
					:class="{
						'mx-13 arrow-size-td': $b.td,
						'mx-8 arrow-size-mobile': $b.m,
						'v-hidden': !canMovePrev,
					}"
					@click="movePrev"
				>
					<v-icon class="arrow-reverse arrow-icon" size="30px">mdi-play</v-icon>
				</v-btn>
				<v-btn
					icon
					class="arrow-background"
					:class="{
						'mx-13 arrow-size-td': $b.td,
						'mx-8 arrow-size-mobile': $b.m,
						'v-hidden': !canMoveNext,
					}"
					@click="moveNext"
				>
					<v-icon size="30px" class="arrow-icon">mdi-play</v-icon>
				</v-btn>
			</div>

			<div
				v-for="(i, index) of data.slides"
				:key="index"
				class="overflow-hidden slide w100"
				:class="{ transitioning: i.transitioning }"
				:style="i.style"
			>
				<Media :src="i.img" :img-class="{ 'zoom-in': i.transitioning }" cover width="100%">
					<div
						class="h100 w100 d-flex justify-center flex-column align-center background-overlay"
						:class="{ 'd-none': !i.transitioning }"
					>
						<h3
							class="font-2 font-sm-5 font-md-7 font-weight-bold white--text"
							:class="{ 'title-active': i.transitioning }"
						>
							{{ i.title }}
						</h3>
						<h4
							class="font-1 font-sm-3 font-md-5 font-weight-medium white--text"
							v-if="i.subtitle"
							:class="{ 'subtitle-active': i.transitioning }"
						>
							{{ i.subtitle }}
						</h4>
						<v-btn
							class="cta font-weight-bold mt-5"
							:to="i.link"
							:small="$b.m"
							:class="{
								'btn-active': i.transitioning && i.subtitle,
								'subtitle-active': i.transitioning && !i.subtitle,
							}"
							>{{ i.cta }}</v-btn
						>
					</div>
				</Media>
			</div>
		</RatioBox>
	</v-container>
</template>

<style scoped>
.background-overlay {
	background-color: #424242aa;
}

.slide {
	z-index: 0;
	position: absolute;
	top: 0;
	opacity: 0;
	z-index: 0;
}

.slide.transitioning {
	transition: opacity 1.5s linear;
}

.v-hidden {
	visibility: hidden;
	pointer-events: none;
}
.arrow-icon {
	color: grey;
}

.arrows-position {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 3;
}

.arrow-background {
	border-radius: 50%;
	background-color: white;
	opacity: 0.8;
	box-shadow: 0 2px 5px grey;
}

.arrow-size-td {
	height: 2.4em;
	width: 2.4em;
}

.arrow-size-mobile {
	height: 2em;
	width: 2em;
}

.arrow-background:hover,
.arrow-background:active {
	opacity: 1;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
	transition-duration: 0.3s, 0s;
	transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1), ease;
	transition-delay: 0s, 0s;
	transition-property: all, visibility;
}

.arrow-reverse {
	rotate: -180deg;
}

.title-active {
	animation: title 2s ease forwards;
}

.subtitle-active {
	animation: subtitle 2s ease forwards;
}

.btn-active {
	animation: btn 2s ease forwards;
}

@keyframes title {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	40% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes subtitle {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	50% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes btn {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	60% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
::v-deep .zoom-in {
	animation: zoomIn 12s ease forwards;
}

@keyframes zoomIn {
	0% {
		scale: 1;
	}

	100% {
		scale: 1.3;
	}
}
</style>
