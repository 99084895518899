<script>
export default {
	inheritAttrs: false,
	props: {
		index: Number,
		data: Object,
	},
}
</script>

<template>
	<Container v-html="data.content"> </Container>
</template>
