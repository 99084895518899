<template>
	<div class="d-flex align-center h100" :class="$b({ xs: 'pa-1' })">
		<div class="text-center w100">
			<h1 class="font-weight-thin font-7">
				{{ data.title }}
			</h1>
			<div v-if="data.subtitle" class="font-weight-light font-4 mt-3">
				{{ data.subtitle }}
			</div>
			<NewsletterForm />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: Object,
	},
}
</script>
