<script>
import numberedPropsToArray from '@/utils/numbered-props-to-array'

export default {
	inheritAttrs: false,
	props: {
		index: Number,
		data: Object,
	},
	computed: {
		bannerItems() {
			return numberedPropsToArray(this.data, 4)
		},
	},
}
</script>

<template>
	<Container>
		<v-row align="center">
			<v-col cols="12" sm="5">
				<BannerItem v-bind="bannerItems[0]" />
			</v-col>

			<v-col cols="12" sm="4">
				<v-row>
					<v-col cols="12">
						<BannerItem v-bind="bannerItems[1]" />
					</v-col>
					<v-col cols="12">
						<BannerItem v-bind="bannerItems[2]" />
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12" sm="3">
				<BannerItem v-bind="bannerItems[3]" />
			</v-col>
		</v-row>
	</Container>
</template>
