var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[_c('v-row',{staticClass:"justify-center mx-1 my-1 align-center",class:[
			(_vm.$bem('__header'),
			{
				'mx-4': _vm.$b.m,
				'mb-4': !_vm.data.subtitle,
				'modern-row': _vm.data.titleStyle == 'modern',
			}) ]},[(_vm.data.titleStyle == 'double')?_c('div',{staticClass:"col d-flex double-col pa-0 mr-2"},[_c('div',{staticClass:"double"}),_c('div',{staticClass:"double"})]):_vm._e(),_c('h2',{staticClass:"header d-block",class:{
				modern: _vm.data.titleStyle == 'modern',
				short: _vm.data.titleStyle == 'short',
				'font-4': _vm.$b.td,
				'font-3': _vm.$b.m,
			}},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),(_vm.data.titleStyle == 'double')?_c('div',{staticClass:"col d-flex double-col pa-0 ml-2"},[_c('div',{staticClass:"double"}),_c('div',{staticClass:"double"})]):_vm._e()]),(_vm.data.subtitle)?_c('v-row',{staticClass:"justify-center mx-1 my-3",class:(_vm.$bem('__header'),
			{
				'mx-4': _vm.$b.m,
			})},[_c('p',{staticClass:"mb-0 font-2 base--text"},[_vm._v(_vm._s(_vm.data.subtitle))])]):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap justify-center align-center py-5"},_vm._l((_vm.filters),function(filter,i){return _c('Autocomplete',{key:i,ref:"input",refInFor:true,staticClass:"mx-3",attrs:{"label":filter.label,"items":filter.items,"disabled":filter.disabled,"loading":_vm.loading === i,"color":_vm.$vars.cta},on:{"change":function($event){return _vm.onFilterChanged(i, $event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.loading === i)?_c('v-progress-circular',{attrs:{"color":_vm.$vars.cta,"size":"20","indeterminate":"","width":"2"}}):(_vm.totalCompleted > i && (_vm.loading === false || _vm.loading > i))?_c('v-icon',{attrs:{"color":_vm.$vars.cta}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',[_vm._v(" $dropdown ")])]},proxy:true}],null,true),model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})}),1),(_vm.shop)?_c('div',{staticClass:"pt-5",class:{ disabled: _vm.loading !== false }},[(_vm.shop.products.length)?_c('ProductsLayout',{staticClass:"justify-center",attrs:{"cards-per-line":4,"products":_vm.shop.products}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }