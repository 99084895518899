<script>
export default {
	name: 'NewsletterForm',
	hooksKey: 'NewsletterForm',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	data() {
		return {
			name: '',
			email: '',
			isLoading: false,
			successMsg: false,
			formMsg: null,
			isSuccess: false,
			validation: {},
			hasError: false,
			rules: {
				required: (value) => !!value || 'Requerido.',
				email: (value) => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Email no valido.'
				},
			},
		}
	},
	computed: {
		fieldsContainErrors() {
			if (!this.$refs) return
			return (
				this.rules.required(this.email) !== true ||
				this.rules.email(this.email) !== true ||
				this.rules.required(this.name) !== true
			)
		},
	},
	methods: {
		saveEmail() {
			this.hasError = false
			if (this.isLoading) return

			this.$shopApi.post({
				// url: 'https://nordenpet.us7.list-manage.com/subscribe/post?u=ab75ea716c5f3156e721184cb&amp;id=ba72ff6e57&amp;f_id=0048d3e4f0',
				url: '/user/savenewsletteremail',
				data: {
					name: this.name,
					email: this.email,
				},
				loading: (v) => (this.isLoading = v),
				onValidation: ({ validation }) => (this.validation = validation),
				onSuccess: () => {
					this.successMsg = `Gracias por registrarte a nuestro newsletter ${this.nombre}.`
					this.isSuccess = true
					this.hasError = false
				},
				onError: (e) => {
					this.isSuccess = false
					this.hasError = true
				},
			})
		},
	},
}
</script>

<template>
	<v-container fluid class="py-4" :class="$bem('newsletter-form')">
		<v-row>
			<v-col cols="12" class="d-flex align-center justify-center py-4">
				<form
					@submit.prevent="saveEmail"
					class="d-flex align-center form-newsletter w100"
					:class="$b({ xs: 'px-6 flex-column' })"
				>
					<TextField
						ref="nameField"
						v-if="!isSuccess"
						:rules="[rules.required]"
						v-model="name"
						label="Nombre"
						solo
						flat
						class="mx-3"
						:class="$b({ xs: 'w100' })"
						hide-details
					>
					</TextField>
					<TextField
						ref="emailField"
						hide-details
						:rules="[rules.required, rules.email]"
						v-if="!isSuccess"
						v-model="email"
						label="Email"
						@keydown.enter="saveEmail"
						solo
						flat
						class="mx-3"
						:class="$b({ xs: 'w100 my-4' })"
					>
					</TextField>
					<Button
						type="submit"
						:loading="isLoading"
						class="mr-2 rounded-xl font-weight-thin px-12"
						color="cta"
						:class="$bem('__btn')"
					>
						Subscribirme
					</Button>
				</form>
			</v-col>
		</v-row>
		<div class="d-flex justify-center w100">
			<div v-if="hasError" class="error-msg error--text rounded">
				Ocurrió un error intentalo nuevamente
			</div>
			<div v-if="isSuccess" class="font-weight-bold">
				{{ successMsg }}
			</div>
		</div>
	</v-container>
</template>

<style lang="scss">
.newsletter-form .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
	background: var(--secondary);
}
</style>

<style lang="scss" scoped>
.form-newsletter {
	width: 85%;
}

.error-msg {
	position: absolute;
	max-width: 700px;
	width: 100%;
}
</style>
