<script>
export default {
	props: {
		data: Object,
	},

	computed: {
		listId() {
			return `${this.data.collectionId}-${this.data.schemaKey}`
		},

		limitedTimeOffer() {
			let offer = this.$store.get('shop/limitedTimeOffer')
			if (this.data.timerType == 'product')
				return offer?.find((item) =>
					this.data.timerProduct.collectionsIds.find((el) => item.collectionId == el)
				)
			else return offer?.find((el) => el.collectionId == this.data.collectionId)
		},
		limitedTimeOfferDate() {
			const date = this.limitedTimeOffer?.untilDate

			const fecha = new Date(date).toLocaleDateString('es-AR', {
				day: 'numeric',
				month: 'long',
				timeZone: 'UTC',
			})
			return `La oferta termina el ${fecha}`
		},
		bgColor() {
			if (this.data.timerType == 'bg') {
				return { backgroundColor: this.data.bgColor }
			} else return null
		},
		textPosition() {
			switch (this.data.bgTextPosition) {
				case 'top':
					return 'justify-start pt-4'

				case 'mid':
					return 'justify-center'

				default:
					return 'justify-end pb-4'
			}
		},
	},

	mounted() {
		if (this.data.products?.length) {
			this.$eventer().trigger('product-list:view', {
				products: this.data.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<Container>
		<Title
			:title="data.title"
			:btn="{ link: data.link, linkText: data.linkText }"
			:titleStyle="data.titleStyle"
		/>

		<v-row
			:class="{ 'flex-row-reverse': data.inverted && $b.td, 'flex-column-reverse': data.inverted && $b.m }"
		>
			<v-col
				class="justify-center d-flex"
				:class="{
					'pa-0 col-3': $b.d,
					'col-12': $b.m,
					'col-4': $b.t,
				}"
			>
				<!-- ProductCard with timer -->
				<div v-if="data.timerType == 'product'" class="w100">
					<ProductCard
						:product="data.timerProduct"
						:list-id="listId"
						featured
						:timerProduct="limitedTimeOffer ? true : false"
						:verticalCard="$b.m ? false : true"
					/>
				</div>

				<!-- Image with timer -->
				<div v-else-if="data.timerType == 'img'" class="h100 w100">
					<SafeLink :to="data.bgLink">
						<Media
							:src="$b.m ? data.imgMobile : data.img"
							width="100%"
							:imgClass="{ 'rounded-lg': $b.td }"
						>
							<div
								class="d-flex flex-column align-center h100 w100 px-6"
								:class="[
									{ 'rounded-lg': $b.td, 'background-overlay': data.bgImgOverlay },
									textPosition,
								]"
							>
								<div class="text-center" v-if="data.bgTitle">
									<h3
										class="font-weight-bold line-clamp-3 strong"
										:class="{ 'font-4': $b.m, 'font-5 mb-1': $b.td }"
									>
										{{ data.bgTitle }}
									</h3>

									<p
										v-if="data.bgSubtitle"
										class="font-weight-medium line-clamp-3 primary"
										:class="{ 'mb-1 font-2': $b.m, 'font-3': $b.td, 'mb-3': $b.d }"
									>
										{{ data.bgSubtitle }}
									</p>
								</div>

								<div v-if="limitedTimeOffer">
									<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard />
									<div class="mt-2">
										<p
											class="text-uppercase line-clamp-3 mb-0 font-weight-medium secondary py-1 px-2 rounded font-0"
										>
											{{ limitedTimeOfferDate }}
										</p>
									</div>
								</div>
							</div>
						</Media>
					</SafeLink>
				</div>

				<!-- BGColor with timer -->
				<SafeLink
					v-else
					:to="data.bgLink"
					class="h100 w100"
					:style="bgColor"
					:class="{ 'rounded-lg': $b.td }"
				>
					<div
						class="d-flex flex-column align-center h100 w100 px-6 py-4"
						:class="[{ 'background-overlay': data.bgImgOverlay }, textPosition]"
					>
						<div class="text-center" v-if="data.bgTitle">
							<h3
								class="font-weight-bold line-clamp-3 strong"
								:class="{ 'font-4': $b.m, 'font-5 mb-1': $b.td }"
							>
								{{ data.bgTitle }}
							</h3>

							<p
								v-if="data.bgSubtitle"
								class="font-weight-medium line-clamp-3 primary"
								:class="{ 'mb-1 font-2': $b.m, 'font-3': $b.td, 'mb-3': $b.d }"
							>
								{{ data.bgSubtitle }}
							</p>
						</div>

						<div v-if="limitedTimeOffer">
							<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard />
							<div class="mt-2">
								<p
									class="text-uppercase line-clamp-3 mb-0 font-weight-medium secondary py-1 px-2 rounded font-0"
								>
									{{ limitedTimeOfferDate }}
								</p>
							</div>
						</div>
					</div>
				</SafeLink>
			</v-col>

			<!-- PRODUCTS CAROUSEL -->
			<v-col class="no-mobile-px" style="min-width: 0; align-self: center">
				<ProductsCarousel
					:products="data.products"
					:list-id="listId"
					arrowsBgHalfRounded
					v-bind="
						$b.props({
							slidesPerPage: { d: 3, t: 2 },
							gutter: 20,
							peekRight: 0,
						})
					"
				/>
			</v-col>
		</v-row>
	</Container>
</template>

<style scoped>
.background-overlay {
	background-color: #424242aa;
}
::v-deep button.ssr-carousel-next-button {
	right: 1%;
}
::v-deep button.ssr-carousel-back-button {
	left: 1%;
}
</style>
