<script>
export default {
	props: {
		data: Object,
	},
	computed: {
		carouselProps() {
			return this.$b.props({
				feather: {
					t: this.data.items.length > 4 && 100,
				},
				showArrows: true,
				slidesPerPage: 1,
				gutter: 10,
				paginateBySlide: true,
				showDots: {
					m: false,
					t: true,
					d: true,
				},
			})
		},
	},
}
</script>
<template>
	<div v-if="$b.d" :class="$bem('desktop-container')">
		<v-row class="items-container px-4">
			<v-col
				v-for="(item, n) in data.items"
				:key="n"
				class="item d-flex align-center justify-center py-3 px-4"
			>
				<SafeLink :to="item.link">
					<div class="base d-flex align-center justify-center" style="gap: 10px">
						<div style="height: 60px" class="d-flex align-center">
							<div v-if="item.useCustomIcon" v-html="item.customIconHtml" class="pa-2"></div>
							<v-icon v-else size="50px" :color="$vars.primary">{{ item.icon }}</v-icon>
						</div>
						<div class="text-left">
							<div class="font-1 header">{{ item.title }}</div>
							<div>{{ item.subtitle }}</div>
						</div>
					</div>
				</SafeLink>
			</v-col>
		</v-row>
	</div>

	<div v-else :class="$bem('mobile-container')" class="base">
		<Carousel loop arrowsBgHalfRounded v-bind="carouselProps" :autoplay-delay="data.autoplayDelay">
			<div
				v-for="(item, i) of data.items"
				:key="i"
				:class="$bem('__card')"
				class="text-center mb-2 py-4 px-8"
			>
				<SafeLink :to="item.link">
					<div class="base py-4" :style="$b.props({ margin: { t: 'auto' }, 'max-width': { t: '90%' } })">
						<div style="height: 60px">
							<div v-if="item.useCustomIcon" v-html="item.customIconHtml"></div>
							<v-icon v-else size="50px" :color="$vars.primary">{{ item.icon }}</v-icon>
						</div>
						<div class="font-2 header">{{ item.title }}</div>
						<div>{{ item.subtitle }}</div>
					</div>
				</SafeLink>
			</div>
		</Carousel>
	</div>
</template>

<style scoped lang="scss">
.desktop-container {
	max-width: 1920px;
	margin: auto;
}

.item {
	border-style: solid;
	border-width: 0 1px 0 0;
	border-color: #c5c4c4;
	transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

	&:last-child {
		border-style: none;
	}
}
</style>
