<script>
export default {
	props: {
		data: Object,
		index: Number,
	},
	data() {
		return {
			tab: null,
		}
	},
	methods: {
		positionIs(position) {
			return this.data.position === position
		},
	},
	computed: {
		carouselConfig() {
			return this.$b({
				d: { slidesPerPage: this.top ? 4 : 3 },
				t: { slidesPerPage: 2 },
				m: { slidesPerPage: 1, peek: 60 },
			})
		},
		left() {
			return this.positionIs('left')
		},
		right() {
			return this.positionIs('right')
		},
		top() {
			return this.$b.m || this.positionIs('top')
		},
	},
}
</script>

<template>
	<Container v-if="data" :class="$bem('carousel-multiple')">
		<Title
			v-if="data.textEnabled"
			:title="data.title"
			:btn="{ link: data.link, linkText: data.linkText }"
			:titleStyle="data.titleStyle"
		/>

		<v-row :class="right && 'flex-row-reverse'">
			<v-col cols="12" :sm="top ? 12 : 2" class="pa-0">
				<v-tabs
					show-arrows
					v-model="tab"
					:centered="top"
					:center-active="top"
					:vertical="$b.d && !top"
					:color="$vars.cta"
					class="h100"
					:class="{
						'slider-right': left && $b.d,
						'top-underline': top && $b.d,
						'slider-top mb-3': top,
						'px-2': !top && $b.m,
					}"
					:slider-color="$vars.cta"
				>
					<v-tab
						v-for="(item, n) in data.tabs"
						:key="n"
						:class="{ 'justify-start': left, 'justify-end': right }"
					>
						{{ item.title }}
					</v-tab>
				</v-tabs>
			</v-col>

			<v-col
				cols="12"
				:sm="top ? 12 : 10"
				class="pa-0"
				:class="{ 'border-left': left, 'border-right': right }"
			>
				<v-tabs-items v-model="tab" touchless>
					<v-tab-item v-for="(item, n) in data.tabs" :key="n" class="pa-1">
						<ProductsCarousel
							list-id="related-products"
							:products="item.products"
							v-bind="carouselConfig"
						/>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</Container>
</template>

<style lang="scss" scoped>
::v-deep .v-tabs-bar--is-mobile::after {
	content: ' ';
	border-bottom: 1px solid #ddd;
	width: 86%;
	position: absolute;
	margin-right: auto;
	margin-left: auto;
	left: 0;
	right: 0;
	bottom: 0;
}

.top-underline ::v-deep .v-slide-group__wrapper::after {
	content: ' ';
	border-bottom: 1px solid #ddd;
	width: 97.5%;
	position: absolute;
	margin-right: auto;
	margin-left: auto;
	left: 0;
	right: 0;
	bottom: 0;
}

::v-deep .v-tab:not(.v-tab--active) {
	color: var(--basetext) !important;
}

.border-left {
	border-left: 1px solid #ddd;
}

.border-right {
	border-right: 1px solid #ddd;
}
.slider-right ::v-deep .v-tabs-slider-wrapper {
	left: auto !important;
	right: 0;
}
::v-deep .v-tab {
	max-width: 100%;
}

::v-deep .v-tabs-items,
::v-deep .v-tabs > .v-tabs-bar {
	background: transparent !important;
}

::v-deep .v-tabs-slider::before {
	content: ' ';
	display: block;
	position: absolute;
	left: 50%;
	border-radius: 10px;
	transform: translateX(-50%);
	background-color: var(--cta);
	width: 12px;

	height: 11px;
	bottom: 38%;
}

.slider-top ::v-deep .v-tabs-slider::before {
	height: 8px;
	bottom: -1px;
}
</style>
