<script>
export default {
	props: {
		data: Object,
	},
	mounted() {
		this.$eventer().trigger('banner:view', {
			images: this.data.images,
			listId: this.listId,
		})
	},
	computed: {
		autoplayDelay() {
			return Number(this.data.autoplayDelay)
		},
		imagesRatio() {
			return this.$b.xs ? 0.636 : 1.8604
		},
		listId() {
			return 'slider-appbar'
		},
	},
}
</script>

<template>
	<v-container fluid v-if="data.images.length" class="slider px-0 py-0">
		<Carousel
			loop
			class="d-flex h100 w100"
			v-bind="
				$b.props({
					autoplayDelay: autoplayDelay,
				})
			"
		>
			<SafeLink v-for="(i, index) of data.images" :key="index" :to="i.link">
				<Media
					cover
					width="100%"
					:src="$b({ m: i.imgMobile || i.img, td: i.img })"
					:aspect-ratio="imagesRatio"
				/>
			</SafeLink>
		</Carousel>
	</v-container>
</template>

<style>
.transparent {
	background: transparent !important;
}
</style>

<style scoped>
::v-deep .ssr-carousel-slides,
::v-deep .ssr-carousel {
	width: 100%;
}
.slider {
	position: relative;
}
</style>
