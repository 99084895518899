<script>
export default {
	props: {
		data: Object,
		index: Number,
	},

	computed: {
		listId() {
			return `${this.data.collectionId}-${this.data.title}`
		},

		rowClass() {
			if (this.$b.d) {
				if (!this.data.inverted) {
					if (this.data.boardEnabled && this.data.boardType != 'img') return 'row'
					else return 'd-flex flex-row align-md-center px-md-3 mt-3'
				} else {
					if (this.data.boardEnabled && this.data.boardType != 'img') return 'row flex-row-reverse'
					else return 'd-flex flex-row-reverse align-md-center px-md-3 mt-3'
				}
			} else {
				if (!this.data.inverted) {
					return 'd-flex flex-column align-md-center px-md-3 mt-3'
				} else {
					return 'd-flex flex-column-reverse align-md-center px-md-3 mt-3'
				}
			}
		},

		carouselConfig() {
			if (this.$b.d) {
				if (this.data.boardType == 'img') {
					// desktop with image
					let img_w = this.data.img.data.width
					if (img_w <= 300) {
						return { slidesPerPage: 3, gutter: 20, peekRight: 0 }
					} else if (img_w <= 360) {
						return { slidesPerPage: 2, gutter: 20, peekRight: 220 }
					} else {
						return { slidesPerPage: 2, gutter: 20, peekRight: 170 }
					}
				} else if (this.data.boardType == 'product' || this.data.boardType == 'bg') {
					// desktop with product or color
					return { slidesPerPage: 3, gutter: 20, peekRight: 0 }
				} else {
					// desktop without image
					return { slidesPerPage: 4 }
				}
			} else if (this.$b.t) {
				// tablet
				return { slidesPerPage: 3 }
			} else {
				// mobile
				return { slidesPerPage: 1, peek: 60 }
			}
		},

		bgColor() {
			if (this.data.boardType == 'bg') {
				return { backgroundColor: this.data.bgColor }
			} else return null
		},

		textPosition() {
			switch (this.data.bgTextPosition) {
				case 'top':
					return 'justify-start pt-4'

				case 'mid':
					return 'justify-center'

				default:
					return 'justify-end pb-4'
			}
		},

		limitedTimeOffer() {
			let offer = this.$store.get('shop/limitedTimeOffer')
			if (this.data.boardType == 'product') {
				return offer?.find((item) =>
					this.data.boardProduct.collectionsIds.find((el) => item.collectionId == el)
				)
			} else return offer?.find((el) => el.collectionId == this.data.collectionId)
		},

		limitedTimeOfferDate() {
			const date = this.limitedTimeOffer?.untilDate

			const fecha = new Date(date).toLocaleDateString('es-AR', {
				day: 'numeric',
				month: 'long',
				timeZone: 'UTC',
			})
			return `La oferta termina el ${fecha}`
		},
	},

	mounted() {
		if (this.data.products?.length) {
			this.$eventer().trigger('product-list:view', {
				products: this.data.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<Container
		v-if="data.products && data.products.length"
		class="align-center no-mobile-px"
		:class="($b({ m: 'pa-0' }), $bem('enhanced-products-carousel'))"
	>
		<Title
			v-if="data.textEnabled"
			:title="data.title"
			:btn="{ link: data.link, linkText: data.linkText }"
			:titleStyle="data.titleStyle"
		/>

		<div :class="rowClass">
			<!-- BOARD -->
			<v-col
				class="justify-center d-flex"
				:class="{
					'pa-0 col-3': $b.d,
					'col-12': $b.mt,
					'minHeighForBgColorMobile pa-0 mb-4': $b.mt && data.boardType == 'bg',
					'py-0 mb-4': $b.m && data.boardType == 'product',
					'pa-0 mb-4': $b.t && data.boardType == 'product',
				}"
				v-if="data.boardEnabled && data.boardType != 'img'"
			>
				<!-- ProductCard board -->
				<div class="w100" v-if="data.boardType == 'product'">
					<ProductCard
						:product="data.boardProduct"
						:list-id="listId"
						:verticalCard="$b.mt ? false : true"
						featured
						:timerProduct="limitedTimeOffer ? true : false"
						:position="data.inverted ? 'left' : 'right'"
					/>
				</div>

				<!-- BGColor board -->
				<SafeLink
					v-if="data.boardType == 'bg'"
					:to="data.bgLink"
					class="w100"
					:style="bgColor"
					:class="{ 'rounded-lg': $b.d }"
				>
					<div
						class="d-flex flex-column align-center h100 w100 px-6 py-4"
						:class="[{ 'background-overlay': data.bgImgOverlay, 'rounded-lg': $b.d }, textPosition]"
					>
						<div class="text-center">
							<h3
								class="font-weight-bold line-clamp-3 strong"
								:class="{ 'font-4': $b.mt, 'font-5 mb-1': $b.d }"
								v-if="data.bgTitle"
							>
								{{ data.bgTitle }}
							</h3>

							<p
								v-if="data.bgSubtitle"
								class="font-weight-medium line-clamp-3 primary"
								:class="{ 'mb-1 font-2': $b.mt, 'font-3 mb-3': $b.d }"
							>
								{{ data.bgSubtitle }}
							</p>
						</div>

						<div v-if="limitedTimeOffer">
							<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard />
							<div class="mt-2">
								<p
									class="text-uppercase line-clamp-3 mb-0 font-weight-medium secondary py-1 px-2 rounded font-0"
								>
									{{ limitedTimeOfferDate }}
								</p>
							</div>
						</div>
					</div>
				</SafeLink>
			</v-col>
			<!-- Image board -->
			<div
				v-if="data.boardEnabled && data.boardType == 'img'"
				:class="{
					'w100 mb-4': $b.mt,
					'flex-shrink-0': $b.d,
					'pr-md-3': !data.inverted,
					'pl-md-3': data.inverted,
				}"
			>
				<SafeLink :to="data.bgLink">
					<Media
						:src="$b.mt ? data.imgMobile : data.img"
						class="h100 w100"
						:img-class="$b({ d: 'rounded-lg' })"
						:width="$b.mt && '100%'"
					>
						<div
							class="d-flex flex-column align-center h100 w100 px-6"
							:class="[
								{ 'rounded-lg': $b.d },
								data.bgImgOverlay && 'background-overlay',
								textPosition,
							]"
						>
							<div>
								<h3
									class="font-weight-bold line-clamp-3 strong"
									:class="{ 'font-4': $b.mt, 'font-5 mb-2': $b.d }"
									v-if="data.bgTitle"
								>
									{{ data.bgTitle }}
								</h3>

								<p
									v-if="data.bgSubtitle"
									class="font-weight-medium line-clamp-3 primary--text"
									:class="{ 'mb-1 font-2': $b.mt, 'font-3 mb-0': $b.d }"
								>
									{{ data.bgSubtitle }}
								</p>
							</div>

							<div v-if="limitedTimeOffer">
								<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard />
								<div class="mt-2">
									<p
										class="text-uppercase line-clamp-3 mb-0 font-weight-medium secondary py-1 px-2 rounded font-0"
									>
										{{ limitedTimeOfferDate }}
									</p>
								</div>
							</div>
						</div>
					</Media>
				</SafeLink>
			</div>

			<!-- PRODUCTS CAROUSEL -->
			<div
				class="w100 no-mobile-px"
				style="min-width: 0; align-self: center"
				:class="{ 'col pa-0': data.boardEnabled && data.boardType != 'img' && $b.d }"
			>
				<ProductsCarousel
					:products="data.products"
					:list-id="listId"
					v-bind="carouselConfig"
					:dotContainerFullWidthMobile="
						Math.round(data.products.length / carouselConfig.slidesPerPage) >= 15
					"
					:arrowsBgHalfRounded="!data.imgEnabled"
				/>
			</div>
		</div>
	</Container>
</template>

<style scoped>
.background-overlay {
	background-color: #424242aa;
}

.minHeighForBgColorMobile {
	min-height: 300px;
}

::v-deep button.ssr-carousel-next-button {
	right: 1%;
}
::v-deep button.ssr-carousel-back-button {
	left: 1%;
}
</style>
