<script>
export default {
	props: {
		data: Object,
	},
	data() {
		return {
			isIntersecting: false,
		}
	},
	computed: {
		videoProps() {
			return { as: 'video', autoplay: 'autoplay', muted: 'muted' }
		},
		src() {
			const getYoutubeUrl = (id) =>
				`https://www.youtube.com/embed/${id}?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1&playlist=${id}`
			const getVimeoUrl = (id) => `https://player.vimeo.com/video/${id}?background=1&muted=1&autoplay=1`

			if (this.isYoutube) return getYoutubeUrl(this.getVideoId)
			else return getVimeoUrl(this.getVideoId)
		},
		getVideoId() {
			if (this.isYoutube) return this.data.src.split('watch?v=').pop()
			else return this.data.src.split('/').pop()
		},
		isYoutube() {
			return this.data.host === 'youtube'
		},
		isSelfHosted() {
			return this.data.host === 'self'
		},
	},
	methods: {
		onIntersect(entries) {
			this.isIntersecting = entries[0].isIntersecting
		},
	},
}
</script>

<template>
	<v-container fluid v-if="data.src" class="px-0 py-0" v-intersect.once="onIntersect">
		<RatioBox width="100%" :aspectRatio="1264 / 609" style="position: relative" class="grey">
			<div class="video-container">
				<div class="video-container">
					<div
						v-if="data.overlayColor"
						class="background-overlay"
						:style="{ 'background-color': data.overlayColor, opacity: data.overlayOpacity }"
					></div>

					<iframe
						v-if="!isSelfHosted"
						id="video-background"
						:src="src"
						frameborder="0"
						allow="autoplay; encrypted-media"
						allowfullscreen
					>
					</iframe>

					<Media v-else style="width: 100%" :src="data.src" :videoProps="videoProps" />

					<div class="overlay-content">
						<h3
							class="font-2 font-sm-5 font-md-7 font-weight-bold white--text"
							:class="{ 'title-active': isIntersecting }"
						>
							{{ data.title }}
						</h3>
						<h4
							class="font-1 font-sm-3 font-md-5 font-weight-medium white--text"
							:class="{ 'subtitle-active': isIntersecting }"
							v-if="data.subtitle"
						>
							{{ data.subtitle }}
						</h4>
						<v-btn
							class="cta font-weight-bold mt-5"
							:class="{ 'btn-active': isIntersecting, 'subtitle-active': isIntersecting }"
							:to="data.link"
							:small="$b.m"
							>{{ data.cta }}</v-btn
						>
					</div>
				</div>
			</div>
		</RatioBox>
	</v-container>
</template>

<style scoped>
.background-overlay {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
}

.video-container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

#video-background {
	position: absolute;
	width: 100vw;
	height: 100vw;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.overlay-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	z-index: 2;
}

.title-active {
	animation: title 2s ease forwards;
}

.subtitle-active {
	animation: subtitle 2s ease forwards;
}

.btn-active {
	animation: btn 2s ease forwards;
}

@keyframes title {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	40% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes subtitle {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	50% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes btn {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	60% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
</style>
