<script>
export default {
	props: {
		data: Object,
	},

	computed: {
		listId() {
			return `${this.data.collectionId}-${this.data.title}`
		},
		textPosition() {
			switch (this.data.textImgPosition) {
				case 'top':
					return 'justify-start pt-4'

				case 'mid':
					return 'justify-center'

				default:
					return 'justify-end pb-4'
			}
		},

		productsLimit() {
			let length = this.data.products?.length
			if (length == 10) return this.data.products?.slice(0, length - 2)
			else if (length > 1 && length % 2 != 0) {
				if (length == 11) return this.data.products?.slice(0, length - 3)
				else return this.data.products?.slice(0, length - 1)
			} else return this.data.products
		},

		colSize() {
			if (this.$b.td) {
				if (!this.data.imgEnabled) {
					if (this.productsLimit.length <= 4) return 'col-6'
					else if (this.productsLimit.length > 4 && this.productsLimit.length < 7) return 'col-4'
					else if (this.productsLimit.length >= 8 && this.productsLimit.length < 12) return 'col-3'
					else return 'col-2'
				} else {
					if (this.productsLimit.length == 8) return 'col-3'
					else if (this.productsLimit.length <= 6 && this.productsLimit.length > 2) return 'col-6'
					else return 'col-12'
				}
			} else {
				if (this.productsLimit.length == 1) return 'col-12'
				else return 'col-6'
			}
		},
	},

	mounted() {
		if (this.data.products?.length) {
			this.$eventer().trigger('product-list:view', {
				products: this.data.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<Container
		v-if="data.products && data.products.length"
		class="align-center no-mobile-px"
		:class="($b({ m: 'pa-0' }), $bem('products-board'))"
	>
		<Title
			:title="data.title"
			:btn="{ link: data.link, linkText: data.linkText }"
			:titleStyle="data.titleStyle"
		/>

		<v-row class="align-center" :class="data.inverted && 'flex-row-reverse'">
			<v-col
				v-if="data.imgEnabled"
				:class="{
					'col-3': $b.d && productsLimit.length <= 4,
					'col-4': $b.d && productsLimit.length > 4,
					'col-12': $b.mt,
				}"
				class="py-0"
			>
				<Media
					:src="$b({ d: data.img, mt: data.imgMobile })"
					cover
					width="100%"
					:img-class="$b({ d: 'rounded-lg' })"
				>
					<div
						class="h100 w100 d-flex flex-column align-center"
						:class="[
							$b({ d: 'rounded-lg', d: textPosition, mt: 'justify-center' }),
							data.textImgOverlay && 'background-overlay',
						]"
						v-if="data.textImgEnabled"
					>
						<h3 class="font-2 font-sm-5 font-md-7 font-weight-bold strong">
							{{ data.titleImg }}
						</h3>
						<Button
							v-if="data.ctaImg"
							:to="data.linkImg"
							text
							class="cta--text px-2"
							:class="{ 'outlined-mobile my-3': $b.m, 'outlined my-6': $b.td }"
						>
							{{ data.ctaImg }}
						</Button>
					</div>
				</Media>
			</v-col>
			<v-col
				:class="data.imgEnabled && { 'py-0 mt-n2': $b.mt }"
				:style="data.imgEnabled && $b({ mt: 'z-index: 1' })"
			>
				<v-row :class="data.imgEnabled && productsLimit.length < 4 && $b({ t: 'justify-center' })">
					<v-col
						v-for="(i, index) of productsLimit"
						:key="index"
						class="d-flex justify-center"
						:class="[{ 'px-0': $b.m, 'px-1': $b.td }, colSize]"
					>
						<ProductCard
							small
							:product="i"
							:listId="listId"
							:listIndex="index"
							:verticalCard="
								!data.imgEnabled && productsLimit.length < 7 && $b.td
									? false
									: !data.imgEnabled && productsLimit.length == 1 && $b.m
									? false
									: data.imgEnabled && productsLimit.length <= 6 && $b.td
									? false
									: true
							"
							:class="{ imgSmall: colSize == 'col-2' }"
						/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</Container>
</template>

<style lang="scss" scoped>
.background-overlay {
	background-color: #424242aa;
}

::v-deep .outlined {
	border: 1px solid var(--cta);
	font-size: 1.3em;
}

::v-deep .outlined-mobile {
	border: 1px solid var(--cta);
	font-size: 1em;
}
</style>
