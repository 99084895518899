<script>
export default {
	props: {
		data: Object,
		index: Number,
	},
	computed: {
		img() {
			return this.$b.m ? this.data.imgMobile : this.data.img
		},
		listId() {
			return 'newsletter'
		},
	},
	mounted() {
		const { title, subtitle } = this.data
		this.$eventer().trigger('newsletter:view', {
			listId: this.listId,
			text: { title, subtitle },
		})
	},
}
</script>

<template>
	<div class="newsletter">
		<NewsletterContent :data="data" />
	</div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

@media #{map-get($display-breakpoints, 'md-and-up')} {
	.text-newsletter {
		width: 80%;
		line-height: 1.4;
		font-size: 1.2em;
	}
}

.newsletter-media {
	min-height: 400px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
	.newsletter-media {
		min-height: 300px;
	}
}
</style>
